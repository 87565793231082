import React from 'react'
import '../../sass/main.scss'
import {Button} from 'react-bootstrap'
import { Link } from 'react-router-dom';


const NotFound = () => {

    window.onload = function(){
        document.querySelector('.container_main').className= "container_main cont_error_active";  
          
        }
    return (
        <div>
           <div className="container_main">
            <div className="cont_error">
                <h1>HTTP 404</h1>  
                    <p>Page Error</p>
                    {/* stylise button */}
                    <Link to="/">
                    <Button className="btn" >Back To Home Page</Button>
                    </Link>
            </div>
                <div className="cont-1"></div>
                <div className="cont-2"></div>
                <div className="cont-3"></div>

            </div>

        </div>
    )
}

export default NotFound
