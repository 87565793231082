import React from 'react';
import ReactDOM from 'react-dom';
// import './sass/main.scss';


// create a not found
// import the compiled css file
// create a button with the primary color
// test


import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
