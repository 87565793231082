import React from 'react';
import "./Services.css"



import Icon1 from '../../images/website1.png';
import Icon2 from '../../images/Next.png';
import Icon3 from '../../images/ChefsHat.png';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';


// add in website links that i have created
const Services = () => {
  return (

    <ServicesContainer id='services'>
      <ServicesH1>My Web Development projects</ServicesH1>
      <ServicesWrapper>
        {/* Wordpress Website */}
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Window Cleaning Man.com</ServicesH2>
          <ServicesP>
          -Wordpress
          </ServicesP>
          <ServicesP>
          -Elementor
          </ServicesP>
          <ServicesP>
          -Hosting Service
          </ServicesP>
          <br/>
          <a className="Button" href="https://www.windowcleaningman.com.au/">Visit Site</a>
        </ServicesCard>
        {/* Next.js Website */}
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Version 1 of portfolio</ServicesH2>
          <ServicesP>
          -Next.js
          </ServicesP>
          <ServicesP>
          -Styled Components
          </ServicesP>
          <ServicesP>
          -Hosted with VERCEL
          </ServicesP>
          <br/>
          <a className="Button" href="https://portfolio-website-main-pym65oyyd-tamatihb.vercel.app/">Visit Site</a>
        </ServicesCard>
        {/* Restaurant Website */}
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Restaurant Website</ServicesH2>
          <ServicesP>
          -React.JS
          </ServicesP>
          <ServicesP>
          -Express JS
          </ServicesP>
          <ServicesP>
          -Google Firebase
          </ServicesP>
          <br/>
          <a className="Button" href="https://github.com/tamatihb/ServerSide2">Visit Repo</a>
        </ServicesCard>

        {/* Framworks/librarys */}
        {/* <ServicesCard>
          <ServicesH2>Website 1</ServicesH2>
          <ServicesP>
          website will display here.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Website 2</ServicesH2>
          <ServicesP>
          website will display here.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Website 3</ServicesH2>
          <ServicesP>
          website will display here.
          </ServicesP>
        </ServicesCard> */}
      </ServicesWrapper>
    </ServicesContainer>
 
  );
};

export default Services;
