import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../videos/video.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowFirstDown,
  ArrowDown
} from './HeroElements';


function HeroSection() {
  // change arrow on hover to arrow
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Welcome</HeroH1>
        <HeroP>
        To my portfolio scroll down and have a look as i showcase my skills.
        </HeroP>
        <HeroBtnWrapper>
          <Button
          //REACT SCROLL
            to='services'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            My Projects {hover ? <ArrowFirstDown /> : <ArrowDown />}
          </Button>
        </HeroBtnWrapper>

      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
