import React from 'react';

// Fa Icons
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';

// React Scroll
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            {/* link to sign in page */}
            <FooterLinkItems>
              <FooterLinkTitle>Account</FooterLinkTitle>
              <FooterLink to='/signin'>Sign in</FooterLink>
               </FooterLinkItems>
               {/* Sign in End */}
               {/* Contact */}
            <FooterLinkItems>
              <FooterLinkTitle>Contact Me</FooterLinkTitle>
              <FooterLink to='/'>Tamatijames9@gmail.com</FooterLink>
            </FooterLinkItems>
            {/* End Contact */}
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            {/* Social Icons */}
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink to='/'>Instagram</FooterLink>
              <FooterLink to='/'>Twitter</FooterLink>
            </FooterLinkItems>
            {/* End Social icons */}
          </FooterLinksWrapper>
        </FooterLinksContainer>
{/* Footer logos and text */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Back To Top 
            </SocialLogo>
            <WebsiteRights>Tamati Harvey © 2022 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.facebook.com' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='https://www.instagram.com' target='_blank' aria-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='https://www.linkedin.com.au'target='_blank' aria-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
           </SocialMediaWrap>
        </SocialMedia>
{/* end logo/text */}

      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
