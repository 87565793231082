import React from 'react';
import NotFound from '../components/NotFound/index';
import ScrollToTop from '../components/ScrollToTop';


function notFound() {
  return (
    <>
      <ScrollToTop />
     <NotFound/>
    </>
  );
}

export default notFound;
