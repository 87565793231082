// Data exported to skills section
export const homeObjTwo = {
  id: 'skills',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'About Me',
  description:
    'Welcome to my portfolio my name is Tamati Harvey-Brewster and i am seeking full time employment as a front end junior web developer. I started my journey half way through 2020 and haven’t looked back.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require ('../../images/Integrated.svg'),
  alt: 'skills',
  darkText: true
};

export const homeObjThree = {
  id: 'Work',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'My work experience and key skills',
  description:
 "Since 2020 I have completed a certificate four in information and technology and have one semester left in a diploma of full stack development at Holmesglen. My projects showcase my many skills and i am able to pick up new frameworks and libraries quickly.",
  imgStart: false,
  img: require('../../images/Succes.png'),
  alt:"experience",
  darkText: true
};
